import React from "react";
import { AppFooterWrapper } from "./applayout.styles";

const AppFooter: React.FC = () => {
    return (
        <AppFooterWrapper>
            <p>Copyright: 2022 The Seven Project. All Rights Reserved</p>
        </AppFooterWrapper>
    );
};

export default AppFooter;
