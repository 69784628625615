import React, { useEffect, useState } from "react";
import AppHeader from "src/components/AppLayout/AppHeader";
import { IAppSlice } from "../../store/slices/app-slice";
import { IReduxState } from "../../store/slices/state.interface";
import { useDispatch, useSelector } from "react-redux";
import { AppCardGroup, AppViewWrapper, AppCardWrapper, Divider, ModalHeader, ModalContainer, ModalFooter, MintFormWrapper, MintForm, FormLabel } from "./appview.styles";
import Container from "../../components/Container";
import Button from "../../components/Button/Button";
import AppFooter from "src/components/AppLayout/AppFooter";

import skeletonSVG from "../../assets/image/skeleton.gif";
import { Box, FormControl, MenuItem, Modal, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField } from "@mui/material";
import { IAccountSlice } from "src/store/slices/account-slice";
import { changeApproval, claimReward, mintNode, withdrawNode } from "src/store/slices/mint-slice";
import { prettyDate } from "../../helpers";

import { useProvider, useNetwork, useAccount, useSigner } from 'wagmi';
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { trim } from '../../helpers/trim';
import { Signer } from "ethers";
import { IPendingTxn, isPendingTxn } from "src/store/slices/pending-txns-slice";
import CloseIcon from '@mui/icons-material/Close';

interface CardProps {
    title?: string;
    content?: any;
    buttonText?: string;
    desc?: string;
    onClick?: any;
    isModal?: boolean;
    disabled?: boolean;
    clickFunc?: Function;
    number?: boolean;
    loading?: boolean;
}

const MintRowData = [
    {
        tokenAmount: { svc: 2500, usdt: 25 },
        label: "Planck SVC | 2500 SVC x 25 USDT | 0.95 SVC per day"
    }, {
        tokenAmount: { svc: 5000, usdt: 50 },
        label: "Femto SVC | 5000 SVC x 50 USDT | 3.8 SVC per day"
    }, {
        tokenAmount: { svc: 10000, usdt: 100 },
        label: "Pico SVC | 10000 SVC x 100 USDT | 11.5 SVC per day"
    }, {
        tokenAmount: { svc: 20000, usdt: 200 },
        label: "Nano SVC | 20000 SVC x 200 USDT | 30.68 SVC per day"
    }, {
        tokenAmount: { svc: 40000, usdt: 400 },
        label: "Mini SVC | 40000 SVC x 400 USDT | 76.7 SVC per day"
    }, {
        tokenAmount: { svc: 80000, usdt: 800 },
        label: "Kilo SVC | 80000SVC x 800 USDT | 184 SVC per day"
    }, {
        tokenAmount: { svc: 160000, usdt: 1600 },
        label: "Mega SVC | 160000 SVC x 1600 USDT | 429.58 SVC per day"
    },
];

const TableData = [
    { nodeType: "Planck SVC", rewardAmount: "0.95 SVC" },
    { nodeType: "Femto SVC", rewardAmount: "3.8 SVC" },
    { nodeType: "Pico SVC", rewardAmount: "11.5 SVC" },
    { nodeType: "Nano SVC", rewardAmount: "30.68 SVC" },
    { nodeType: "Mini SVC", rewardAmount: "76.7 SVC" },
    { nodeType: "Kilo SVC", rewardAmount: "184 SVC" },
    { nodeType: "Mega SVC", rewardAmount: "429.58 SVC" },
    { nodeType: "Giga SVC", rewardAmount: "1000 SVC" }
]

const AppCard: React.FC<CardProps> = ({ onClick, title, disabled, content, buttonText = "", desc, number, loading }) => {
    return (
        <AppCardWrapper>
            <h3>{title}</h3>
            <h4 style={{ height: '40px', fontSize: number ? '25px' : '16px' }}>{number && !loading ? trim(content, 3) : content}</h4>
            {buttonText && <Button disabled={disabled} label={buttonText} onClick={onClick} />}
            <h5>{desc}</h5>
        </AppCardWrapper>
    );
};

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxHeight: '70vh',
    padding: '20px 20px',
    bgcolor: "background.paper",
    borderRadius: 2,
    boxShadow: "0 0 20px #00000080",
    overflow: 'scroll',
    overflowX: 'scroll'

};

const AppView: React.FC = () => {

    const app = useSelector<IReduxState, IAppSlice>(state => state.app);
    const account = useSelector<IReduxState, IAccountSlice>(state => state.account);
    const dispatch = useDispatch();
    const provider = useProvider() as JsonRpcProvider | StaticJsonRpcProvider;
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { data: signer } = useSigner();
    const isloading = app.loading || account.loading;
    const pendingTxs = useSelector<IReduxState, IPendingTxn[]>(state => state.pendingTransactions);

    const cardData1 = [
        {
            title: "SVC on QuickSwap",
            content: "Buy at least 25 SVC for a node",
            buttonText: "💰 Buy a SVC bag",
            clickFunc: () => { window.open("https://legacy.quickswap.exchange/#/swap?outputCurrency=0x9aA68BA3746D05009135D5f33D3Ced069dcA719b", '_blank') }
        },
        {
            title: "USDT on QuickSwap",
            content: "Buy at least 25 USDT for a node",
            buttonText: "💰 Buy a USDT bag",
            clickFunc: () => { window.open("https://legacy.quickswap.exchange/#/swap?outputCurrency=0xc2132D05D31c914a87C6611C10748AEb04B58e8F", '_blank') }
        },
    ];

    const cardData2 = [
        {
            title: "My total rewards",
            content: isloading ? <img src={skeletonSVG} alt="skeleton" /> : account.balances.pendingReward,
        },
        {
            title: "Total nodes in pool",
            content: isloading ? <img src={skeletonSVG} alt="skeleton" /> : app.totalNodeNum,
        },
        {
            title: "SVC in rewards pool",
            content: isloading ? <img src={skeletonSVG} alt="skeleton" /> : app.svcDaoBalance,
        }

    ];

    const handleScroll = () => {
        const header = (document.querySelector("#header") as HTMLElement);
        if (document.documentElement.scrollTop > 0) {
            header.style.boxShadow = "0 1rem 2rem rgb(31 45 61 / 20%)";
        } else {
            header.style.boxShadow = "none";
        }
    };
    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        return () => {
            document.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const [open, setOpen] = useState(false);
    const [modal, setModal] = useState("");
    const handleOpen = (key: string) => {
        setModal(key);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const handleClaim = () => {
        if (address && chain && provider)
            dispatch(claimReward({ address, networkID: chain.id, provider, signer: signer as Signer }));
    }
    return (
        <>
            <AppHeader />
            <AppViewWrapper>
                <Container>
                    <h2 style={{ marginTop: '90px', marginBottom: 0, color: 'rgb(24, 104, 183)', textAlign: 'center' }}>Passive Income / Easy To Use</h2>
                    <p style={{ color: "orange", textAlign: "center", marginTop: "10px", marginBottom: "10px", fontSize: "14px" }}><b style={{ fontSize: "16px" }}>Note:</b> Rewards can be claimed after 90 days once the node is registered</p>
                    <AppCardGroup repeat={3} padding>
                        <AppCard
                            title={"Number of nodes"}
                            content={isloading ? <img src={skeletonSVG} alt="skeleton" /> : account.balances.numNodes}
                            buttonText="🧊 Your nodes"
                            disabled={isloading}
                            onClick={() => handleOpen("node")}
                            number={true}
                            loading={isloading}
                        />
                        <AppCard
                            title={"Claimable rewards"}
                            content={isloading ? <img src={skeletonSVG} alt="skeleton" /> : account.balances.claimableReward}
                            buttonText={"🤑 Claim"}
                            onClick={() => handleClaim()}
                            disabled={
                                (
                                    !account.balances.canClaim ||
                                    account.balances.claimableReward == 0
                                ) ||
                                isPendingTxn(pendingTxs, 'claim_reward')
                            }
                            number={true}
                            loading={isloading}
                        />

                        <AppCard
                            title={"Mint a liquidity node"}
                            disabled={account.loading}
                            content={"Provide tokens: SVC - USDT"}
                            buttonText={"💦  Mint node"}
                            onClick={() => handleOpen("mint")}
                        />

                        {cardData2.map((item: CardProps, key: number) => {
                            return item.title ? <AppCard {...item} key={key} number={true} loading={isloading} /> : <div />;
                        })}
                        <div />
                    </AppCardGroup>
                    <Divider />
                    <AppCardGroup repeat={3} padding>
                        {cardData1.map((item: CardProps, key: number) => {
                            return item.title ? <AppCard {...item} key={key} onClick={item.clickFunc} /> : <div />;
                        })}
                    </AppCardGroup>
                </Container>
                <AppFooter />
                <Modal open={open} onClose={handleClose} >
                    <Box
                        sx={{
                            ...style,
                            width: { xs: '95%', md: '600px' }
                        }}
                    >
                        {modal === "node" ? <NodeModal onCancel={handleClose} /> : <MintModal onCancel={handleClose} />}
                    </Box>
                </Modal>
            </AppViewWrapper>
        </>
    );
};

const NodeModal: React.FC<any> = ({ onCancel }) => {

    const account = useSelector<IReduxState, IAccountSlice>(state => state.account);
    const claimDuration = useSelector<IReduxState, number>(state => state.app.claimDuration);
    const nodes = account.balances.nodes;
    const provider = useProvider() as JsonRpcProvider | StaticJsonRpcProvider;
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { data: signer } = useSigner();
    const dispatch = useDispatch();
    const pendingTxs = useSelector<IReduxState, IPendingTxn[]>(state => state.pendingTransactions);

    function onClickWithdraw(nodeIndex: number) {
        if (address && provider && chain?.id) {
            dispatch(
                withdrawNode({
                    nodeIndex,
                    address,
                    networkID: chain.id,
                    provider,
                    signer: signer as Signer
                })
            );
        }
    }
    function createData(node: number, nodeType: string, created: string, rewards: string, claimableDate: string) {
        return { node, nodeType, created, rewards, claimableDate };
    }
    let rows: Array<any> = [];
    for (let i = 0; i < nodes?.length; i++) {
        rows.push(
            createData(
                (i + 1),
                TableData[nodes[i][0].toNumber()].nodeType,
                prettyDate(nodes[i][1].toNumber()),
                TableData[nodes[i][0].toNumber()].rewardAmount,
                prettyDate(nodes[i][1].toNumber() + claimDuration)
            )
        );
    }
    return (
        <div>
            <ModalHeader>Your SVC Nodes</ModalHeader>
            <ModalContainer>
                <div style={{ overflowX: 'auto', padding: '20px' }}>
                    <Table sx={{ '& .MuiTableCell-root': { fontFamily: 'Square' } }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>Node</TableCell>
                                <TableCell>Node type</TableCell>
                                <TableCell>Created At</TableCell>
                                <TableCell >Daily Reward</TableCell>
                                <TableCell>Claimable Date</TableCell>
                                <TableCell >Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row, key) => (
                                <TableRow key={key}>
                                    <TableCell>{row.node}</TableCell>
                                    <TableCell>{row.nodeType}</TableCell>
                                    <TableCell>{row.created}</TableCell>
                                    <TableCell>{row.rewards}</TableCell>
                                    <TableCell>{row.claimableDate}</TableCell>
                                    <TableCell>
                                        <button
                                            style={{
                                                backgroundColor: 'transparent',
                                                fontFamily: 'Square',
                                                border: 'none',
                                                color: 'rgb(24, 104, 183)',
                                                cursor: 'pointer'
                                            }}
                                            onClick={() => onClickWithdraw(key)}
                                            disabled={
                                                isPendingTxn(pendingTxs, 'withdraw_node')
                                            }
                                        >
                                            Withdraw
                                        </button>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </div>
            </ModalContainer>
            <ModalFooter>
                <Button label={"Close"} onClick={onCancel} />
            </ModalFooter>
        </div>
    );
};

const MintModal: React.FC<any> = ({ onCancel }) => {

    const provider = useProvider() as JsonRpcProvider | StaticJsonRpcProvider;
    const { address } = useAccount();
    const { chain } = useNetwork();
    const { data: signer } = useSigner();
    const dispatch = useDispatch();
    const account = useSelector<IReduxState, IAccountSlice>(state => state.account);
    const [isMint, setMint] = useState(false);
    const [selected, setSelect] = useState(-1);
    const [tokenAmount, setTokenAmount] = useState<any>({});

    const pendingTxs = useSelector<IReduxState, IPendingTxn[]>(state => state.pendingTransactions);


    const handleNext = () => {
        if (selected > -1) {
            setMint(true);
        }
    };
    const handleMint = async () => {
        if (address && provider && chain?.id) {
            dispatch(
                mintNode({
                    type: selected,
                    address,
                    networkID: chain.id,
                    provider,
                    signer: signer as Signer
                })
            );
        }
    };
    const handleChange = (event: any) => {
        setSelect(event.target.value);
        setTokenAmount(MintRowData[event.target.value].tokenAmount);
    };
    const handleProvideSVC = async () => {
        if (address && provider && chain?.id) {
            dispatch(
                changeApproval({
                    token: "svc",
                    amount: tokenAmount.svc,
                    provider,
                    networkID: chain.id,
                    address,
                    signer: signer as Signer
                })
            );
        }
    };
    const handleProvideUSDT = async () => {
        if (address && provider && chain?.id) {
            dispatch(
                changeApproval({
                    token: "usdt",
                    amount: tokenAmount.usdt,
                    provider,
                    networkID: chain.id,
                    address,
                    signer: signer as Signer
                })
            );
        }
    };
    return (
        <div style={{ position: 'relative' }}>
            <div
                style={{ position: 'fixed', top: '40px', right: '30px', cursor: 'pointer' }}
                onClick={onCancel}
            >
                <CloseIcon
                    color="primary"
                />
            </div>
            <ModalHeader>
                Mint a SVC node
            </ModalHeader>
            {isMint ? (
                <ModalContainer>
                    <MintFormWrapper>
                        <FormLabel>SVC Balance : {trim(Number(account.balances.svc), 2)}</FormLabel>
                        <MintForm>
                            <Button
                                label={`Provide ${tokenAmount.svc} SVC`}
                                disabled={
                                    (account.balances.svcAllowed >= tokenAmount.svc
                                        || account.balances.svc < tokenAmount.svc) || (
                                        isPendingTxn(pendingTxs, 'approve_svc')
                                    )
                                }
                                onClick={handleProvideSVC}
                            />
                            {
                                (account.balances.svcAllowed >= tokenAmount.svc
                                    && account.balances.svc >= tokenAmount.svc)
                                && <i className="bi bi-check2"></i>
                            } {
                                isPendingTxn(pendingTxs, 'approve_svc') &&
                                <img src={skeletonSVG} alt="skeleton" style={{ padding: '5px', width: '40px', height: '40px' }} />
                            }
                        </MintForm>
                    </MintFormWrapper>
                    <MintFormWrapper>
                        <FormLabel>USDT Balance : {trim(Number(account.balances.usdt), 2)}</FormLabel>
                        <MintForm>
                            <Button
                                label={`Provide ${tokenAmount.usdt} USDT`}
                                onClick={handleProvideUSDT}
                                disabled={
                                    (account.balances.usdtAllowed >= tokenAmount.usdt
                                        || account.balances.usdt < tokenAmount.usdt) || (
                                        isPendingTxn(pendingTxs, 'approve_usdt')
                                    )
                                }
                            />
                            {
                                (account.balances.usdtAllowed >= tokenAmount.usdt
                                    && account.balances.usdt >= tokenAmount.usdt)
                                && <i className="bi bi-check2"></i>
                            }
                            {
                                isPendingTxn(pendingTxs, 'approve_usdt') &&
                                <img src={skeletonSVG} alt="skeleton" style={{ padding: '5px', width: '40px', height: '40px' }} />
                            }
                        </MintForm>
                    </MintFormWrapper>
                </ModalContainer>
            ) : (
                <ModalContainer>

                    <div className="select-label">Select a SVC node type...</div>
                    <FormControl size="small" style={{ width: "90%" }} >
                        <Select
                            labelId="demo-select-small"
                            id="demo-select-small"
                            value={selected}
                            label="Age"
                            style={{ width: "100%", fontFamily: 'Square' }}
                            onChange={handleChange}
                        >
                            <MenuItem value={-1}>
                                <em>Select your SVC node</em>
                            </MenuItem>
                            {MintRowData.map((item: any, key: any) => (
                                <MenuItem value={key} key={key} sx={{ fontFamily: 'Square' }}>
                                    {item.label}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                </ModalContainer>
            )}
            <ModalFooter>
                <Button
                    label={
                        isMint ?
                            (
                                isPendingTxn(pendingTxs, 'mint_node') ?
                                    <>
                                        <img
                                            src={skeletonSVG}
                                            alt="skeleton"
                                            style={{ padding: '5px', width: '35px', height: '40px' }}
                                        /><span>Mint Node</span>
                                    </>
                                    :
                                    'Mint Node'
                            ) :
                            "Next"
                    }
                    onClick={isMint ? handleMint : handleNext}
                    disabled={
                        isMint && (
                            account.balances.svcAllowed < tokenAmount.svc ||
                            account.balances.svc < tokenAmount.svc ||
                            account.balances.usdtAllowed < tokenAmount.usdt ||
                            account.balances.usdt < tokenAmount.usdt
                        ) ||
                        isPendingTxn(pendingTxs, 'mint_node')
                    }
                />
            </ModalFooter>
        </div>
    );
};

export default AppView;
