import { Networks } from "./blockchain";

const MATIC_MAINNET = {
    SVC_ADDRESS: "0x9aA68BA3746D05009135D5f33D3Ced069dcA719b",
    DAO_ADDRESS: "0x3DC4c4fe6dFA3437dAf43e7bEebcCFaE13b25187",
    USDT_ADDRESS: "0xc2132D05D31c914a87C6611C10748AEb04B58e8F"
};

export const getAddresses = (networkID: number) => {
    if (networkID === Networks.MATIC) return MATIC_MAINNET;
    throw Error("Network don't support");
};

