import styled from "styled-components";

export const AppViewWrapper = styled.div`
    min-height: calc(100vh);
    display:flex;
    flex-direction:column;
    justify-content:space-between;
`;

export const AppCardGroup = styled.div<{
    repeat: number; padding?: boolean
}>`
    display: flex;
    flex-direction:row;
    justify-content:center;
    align-content:center;
    flex-wrap: wrap;
`;

export const AppCardWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #eee;
    padding: 1rem 1rem;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-radius: 1rem;
    min-height:160px;
    min-width:350px;
    max-width: 500px;
    margin:  1em;
    width: auto;
    h3,
    h4,
    h5 {
        margin: 0;
        color: #333;
        text-align: center;
    }
    h3 {
        font-weight: 500;
        font-size: 1.25rem;
        line-height: 1.2;
        margin-bottom: 0.5rem;
    }
    h4 {
        margin-bottom: 1rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
    }
    h5 {
        font-size: 0.9rem;
        font-weight: 400;
        line-height: 1.5;
    }
    img {
        width: 40px;
    }
    @media screen and (max-width: 480px){
        width:90%;
        margin:auto;
        margin: 3%;
        min-width: auto;
    }
`;

export const Divider = styled.div`
    width: 100%;
    height: 2px;
    background-color: #ddd;
    margin: 1em;
`;

export const ModalHeader = styled.div`
    font-size: 20px;
    padding: 20px 30px 0;
`;

export const ModalContainer = styled.div`
    padding: 20px 5px;
    display: flex;
    flex-direction: column;
    .select-label {
        padding: 0 0 20px;
    }
`;

export const ModalFooter = styled.div`
    padding: 20px 30px;
    display: flex;
    justify-content: flex-end;
`;

export const MintFormWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin:10px 0 20px;
    width: 100%;
`;
``;

export const FormLabel = styled.div``;

export const MintForm = styled.div`
    padding-top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 100%;
    i {
        font-size: 40px;
        font-weight: 900;
        color: rgb(24, 104, 183);
    }
`;
