
import { ConnectButton as RainbowConnectButton } from "@rainbow-me/rainbowkit";
import styled from "styled-components";


const ConnectButton = styled.div`
    background-color:#fff;
    color: rgb(24 104 183);
    font-size: 1rem;
    font-family:Square;
    cursor: pointer;
    padding: 8px 10px;
    border-radius: 10px;
`

const InPageConnectButton = () => {

    return (
        <RainbowConnectButton.Custom >
            {({
                account,
                chain,
                openAccountModal,
                openChainModal,
                openConnectModal,
                mounted,
            }) => {
                return (
                    <div
                        {...(!mounted && {
                            'aria-hidden': true,
                            'style': {
                                opacity: 0,
                                pointerEvents: 'none',
                                userSelect: 'none',
                            },
                        })}
                    >
                        {(() => {
                            if (!mounted || !account || !chain) {
                                return (
                                    <ConnectButton onClick={openConnectModal}>
                                        Connect Wallet
                                    </ConnectButton>
                                );
                            }

                            if (chain.unsupported) {
                                return (
                                    <ConnectButton onClick={openChainModal} >
                                        Wrong network
                                    </ConnectButton>
                                );
                            }

                            return (
                                <div style={{ display: 'flex', gap: 12 }}>
                                    {/* <button
                                        onClick={openChainModal}
                                        style={{ display: 'flex', alignItems: 'center' }}
                                    >
                                        {chain.name}
                                    </button> */}

                                    <ConnectButton onClick={openAccountModal} >
                                        {account.displayName}
                                    </ConnectButton>
                                </div>
                            );
                        })()}
                    </div>
                );
            }}
        </RainbowConnectButton.Custom>
    );
};


export default InPageConnectButton;