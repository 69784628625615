
import App from "./App";
import { HashRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import "./style.scss";

function Root() {

    const app = () => (

        <HashRouter>
            <ToastContainer autoClose={3000} limit={3} pauseOnFocusLoss={false} />
            <App />
        </HashRouter>

    );

    return app();
}

export default Root;
