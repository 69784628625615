import { ethers, Signer } from "ethers";
import { getAddresses } from "../../constants";
import { fetchPendingTxns, clearPendingTxn } from "./pending-txns-slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { loadAccountDetails } from "./account-slice";
import { Networks } from "../../constants/blockchain";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";
import { metamaskErrorWrap } from "../../helpers/metamask-error-wrap";
import { sleep } from "../../helpers";
import { toast } from 'react-toastify';

import { USDTContract, SVCContract, DaoContract } from "src/abi";
import { loadAppDetails } from "./app-slice";
import React from "react";

interface IChangeApproval {
    token: "usdt" | "svc";
    amount: number;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
    signer: Signer;
    networkID: Networks;
    address: string;
}

export const changeApproval = createAsyncThunk("bonding/changeApproval", async ({ token, amount, provider, networkID, address, signer }: IChangeApproval, { dispatch }) => {

    if (!provider) {
        return;
    }
    const addresses = getAddresses(networkID);
    let tokenContract: any;
    let toastId: any;
    let amountToApprove: any;

    if (token == "usdt") {
        tokenContract = new ethers.Contract(addresses.USDT_ADDRESS, USDTContract, signer);
        amountToApprove = ethers.utils.parseUnits(String(amount), 6);
    } else if (token == "svc") {
        tokenContract = new ethers.Contract(addresses.SVC_ADDRESS, SVCContract, signer);
        amountToApprove = ethers.utils.parseUnits(String(amount), 18);

    }
    let approveTx;
    try {
        dispatch(
            fetchPendingTxns({
                text: "Approving " + token,
                type: "approve_" + token,
            }),
        );
        toastId = toast("Waiting approve transaction to be confirmed", { autoClose: false });
        const gasPrice = await getGasPrice(provider);
        approveTx = await tokenContract.approve(addresses.DAO_ADDRESS, amountToApprove, { gasPrice });
        await approveTx.wait();
        await dispatch(loadAccountDetails({ networkID, provider, address }));
        toast.update(toastId, { render: "Transaction was successfully sent", type: toast.TYPE.INFO, autoClose: 2000 });
    } catch (err: any) {
        metamaskErrorWrap(err, dispatch);
        toast.update(toastId, { render: "User declined transaction", type: toast.TYPE.ERROR, autoClose: 2000 });
    } finally {
        dispatch(clearPendingTxn(`approve_${token}`));
    }
    await sleep(2);
});


interface IMintNode {
    type: number;
    address: string;
    networkID: Networks;
    signer: Signer;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}
export const mintNode = createAsyncThunk("minting/mintNode", async ({ type, address, networkID, provider, signer }: IMintNode, { dispatch }) => {

    const addresses = getAddresses(networkID);
    let displayName: string = "";
    if (type == 0) {
        displayName = "Planck SVC";
    } else if (type == 1) {
        displayName = "Femto SVC";
    } else if (type == 2) {
        displayName = "Pico SVC";
    } else if (type == 3) {
        displayName = "Nano SVC";
    } else if (type == 4) {
        displayName = "Mini SVC";
    } else if (type == 5) {
        displayName = "Kilo SVC";
    } else if (type == 6) {
        displayName = "Mega SVC";
    }
    const daoContract = new ethers.Contract(addresses.DAO_ADDRESS, DaoContract, signer);
    let mintTx;
    let toastId: any;
    try {
        dispatch(
            fetchPendingTxns({
                text: "Minting Node " + displayName,
                type: "mint_node",
            }),
        );
        toastId = toast("Waiting minting transaction to be confirmed", { autoClose: false });
        const gasPrice = await getGasPrice(provider);
        mintTx = await daoContract.mintNode(address, type, { gasPrice });
        await mintTx.wait();
        await dispatch(loadAccountDetails({ networkID, provider, address }));
        await dispatch(loadAppDetails({ networkID, provider }));
        toast.update(toastId, { render: "Transaction was successfully sent", type: toast.TYPE.INFO, autoClose: 2000 });
    } catch (err: any) {
        metamaskErrorWrap(err, dispatch);
        toast.update(toastId, { render: "User declined transaction", type: toast.TYPE.ERROR, autoClose: 2000 });
    } finally {
        dispatch(clearPendingTxn('mint_node'));
    }
});

interface IClaimReward {
    address: string;
    networkID: Networks;
    signer: Signer;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const claimReward = createAsyncThunk("minting/claimReward", async ({ address, networkID, provider, signer }: IClaimReward, { dispatch }) => {
    if (!provider) {
        return;
    }
    const addresses = getAddresses(networkID);
    const daoContract = new ethers.Contract(addresses.DAO_ADDRESS, DaoContract, signer);
    let claimTx;
    let toastId: any;
    try {
        dispatch(
            fetchPendingTxns({
                text: "Claim Reward",
                type: 'claim_reward',
            }),
        );
        toastId = toast("Waiting claim transaction to be confirmed", { autoClose: false });
        const gasPrice = await getGasPrice(provider);
        claimTx = await daoContract.claimRewards(address, { gasPrice });
        await claimTx.wait();
        await dispatch(loadAccountDetails({ networkID, provider, address }));
        toast.update(toastId, { render: "Transaction was successfully sent", type: toast.TYPE.INFO, autoClose: 2000 });
    } catch (err: any) {
        metamaskErrorWrap(err, dispatch);
        toast.update(toastId, { render: "User declined transaction", type: toast.TYPE.ERROR, autoClose: 2000 });
    } finally {
        dispatch(clearPendingTxn('claim_reward'));
    }
});

interface IWithdrawNode {
    address: string;
    networkID: Networks;
    signer: Signer;
    nodeIndex: number;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const withdrawNode = createAsyncThunk("withdraw/withdrawNode", async ({ nodeIndex, address, networkID, provider, signer }: IWithdrawNode, { dispatch }) => {
    if (!provider) {
        return;
    }
    const addresses = getAddresses(networkID);
    const daoContract = new ethers.Contract(addresses.DAO_ADDRESS, DaoContract, signer);

    let withdrawTx;
    let toastId: any;
    try {
        dispatch(
            fetchPendingTxns({
                text: "Withdraw Node",
                type: 'withdraw_node',
            }),
        );
        toastId = toast("Waiting withdraw transaction to be confirmed", { autoClose: false });
        const gasPrice = await getGasPrice(provider);
        withdrawTx = await daoContract.withdrawNode(address, nodeIndex, { gasPrice });
        await withdrawTx.wait();
        await dispatch(loadAccountDetails({ networkID, provider, address }));
        toast.update(toastId, { render: "Transaction was successfully sent", type: toast.TYPE.INFO, autoClose: 2000 });
    } catch (err: any) {
        metamaskErrorWrap(err, dispatch);
        toast.update(toastId, { render: "User declined transaction", type: toast.TYPE.ERROR, autoClose: 2000 });
    } finally {
        dispatch(clearPendingTxn('withdraw_node'));
    }
});



