import ReactDOM from "react-dom";
import Root from "./Root";
import React from 'react';
import store from "./store/store";
import { Provider } from "react-redux";


// import "@rainbow-me/rainbowkit/styles.css";
import 'src/assets/rainbowkit.css';

import { wagmiClient, chains } from "src/hooks/wagmi";

import { WagmiConfig } from 'wagmi'

import {
    lightTheme as rainbowLightTheme,
    RainbowKitProvider,
} from "@rainbow-me/rainbowkit";



ReactDOM.render(
    <React.StrictMode>
        <WagmiConfig client={wagmiClient}>
            <Provider store={store}>
                <RainbowKitProvider
                    appInfo={{
                        appName: 'seven.money',
                    }}
                    chains={chains}
                    theme={
                        rainbowLightTheme({ accentColor: "#E0E2E3", accentColorForeground: "#181A1D" })
                    }
                >
                    <Root />
                </RainbowKitProvider>
            </Provider>
        </WagmiConfig>
    </React.StrictMode>,
    document.getElementById("root")
);
