import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { SVCContract } from "../../abi";
import { USDTContract } from "../../abi";
import { DaoContract } from "../../abi";
import { setAll } from "../../helpers";

import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../../constants/blockchain";
import { RootState } from "../store";
import { trim } from "../../helpers"
interface ILoadAccountDetails {
    address: string;
    networkID: Networks;
    provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IUserAccountDetails {
    balances: {
        svc: number;
        svcAllowed: number;
        usdt: number;
        usdtAllowed: number;
        canClaim: boolean;
        nodes: Array<any>;
        numNodes: number;
        claimableReward: string;
        pendingReward: string;
    };
}

export const loadAccountDetails = createAsyncThunk("account/loadAccountDetails", async ({ networkID, provider, address }: ILoadAccountDetails): Promise<IUserAccountDetails> => {


    const addresses = getAddresses(networkID);
    const svcContract = new ethers.Contract(addresses.SVC_ADDRESS, SVCContract, provider);
    const svcBalance = (await svcContract.balanceOf(address)) / Math.pow(10, 18);
    const svcAllowed = (await svcContract.allowance(address, addresses.DAO_ADDRESS)) / Math.pow(10, 18);

    const usdtContract = new ethers.Contract(addresses.USDT_ADDRESS, USDTContract, provider);
    /**
     * decimals of USDT mainnet contract is 6
     * decimals of USDT testnet contract is 18
     * i have changed from 18 to 6 for mainnet
     */
    const usdtBalance = (await usdtContract.balanceOf(address)) / Math.pow(10, 6);
    const usdtAllowed = (await usdtContract.allowance(address, addresses.DAO_ADDRESS)) / Math.pow(10, 6);
    const daoContract = new ethers.Contract(addresses.DAO_ADDRESS, DaoContract, provider);
    const [nodes, numNodes] = await daoContract.getUserInfo(address);

    const claimableReward = (await daoContract.getUserClaimableRewards(address)) / Math.pow(10, 18);
    const pendingReward = (await daoContract.getUserRewards(address)) / Math.pow(10, 18);
    const canClaim = (await daoContract.canClaimReward(address));
    return {
        balances: {
            svc: svcBalance,
            svcAllowed: svcAllowed,
            usdt: usdtBalance,
            usdtAllowed: usdtAllowed,
            canClaim: canClaim,
            nodes: nodes,
            numNodes: numNodes.toNumber(),
            claimableReward: trim(claimableReward, 5),
            pendingReward: trim(pendingReward, 5)
        },
    };
});


export interface IAccountSlice {
    balances: {
        svc: string;
        svcAllowed: string;
        usdt: string;
        usdtAllowed: string;
        canClaim: boolean;
        nodes: any;
        numNodes: number;
        claimableReward: number;
        pendingReward: number;
    };
    loading: boolean;
}

const initialState: IAccountSlice = {
    loading: true,
    balances: { svc: "0", svcAllowed: "0", usdt: "0", usdtAllowed: "0", canClaim: false, numNodes: 0, claimableReward: 0, pendingReward: 0, nodes: Array<any>() }
};

const accountSlice = createSlice({

    name: "account",
    initialState,
    reducers: {
        fetchAccountSuccess(state, action) {
            setAll(state, action.payload);
        },
        reset(state) {
            Object.assign(state, initialState)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(loadAccountDetails.pending, state => {
                state.loading = true;
            })
            .addCase(loadAccountDetails.fulfilled, (state, action) => {
                setAll(state, action.payload);
                state.loading = false;
            })
            .addCase(loadAccountDetails.rejected, (state, { error }) => {
                state.loading = false;
                console.log(error);
            })
    },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess, reset } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
