import React from "react";
import { LandingFooterContainer, LandingFooterWrapper } from "./layout.styles";
import Container from "../Container";

const LandingFooter = () => {
    return (
        <LandingFooterWrapper>
            <Container>
                <LandingFooterContainer>
                    <p>Copyright: 2022 The Seven Project.</p>
                    <p>&nbsp;&nbsp;All Rights Reserved</p>
                </LandingFooterContainer>
            </Container>
        </LandingFooterWrapper>
    );
};

export default LandingFooter;
