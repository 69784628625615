import { useEffect, useState, useCallback } from "react";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useAccount, useProvider, useNetwork } from 'wagmi'
import { loadAppDetails } from "../store/slices/app-slice";
import { loadAccountDetails, reset } from "../store/slices/account-slice";

import LandingView from "src/views/Landing/LandingView";
import AppView from "src/views/AppView/AppView";


function App() {
    const dispatch = useDispatch();
    const { address, isConnected: connected } = useAccount();
    const provider = useProvider();
    const { chain } = useNetwork();

    async function loadDetails(whichDetails: string) {
        let loadProvider = provider;

        if (whichDetails === "app") {
            loadApp(loadProvider);
        }

        if (whichDetails === "account" && address && connected) {
            loadAccount(loadProvider);
        }

    }

    const loadApp = useCallback(
        (loadProvider: any) => {
            if (chain) {
                dispatch(loadAppDetails({ networkID: chain?.id, provider: loadProvider }));
            }
        },
        [connected, provider, address],
    );

    const loadAccount = useCallback(
        (loadProvider: any) => {
            if (chain && address) {
                dispatch(loadAccountDetails({ networkID: chain?.id, address, provider: loadProvider }));
            }
        },
        [connected, provider, address],
    );

    useEffect(() => {
        if (connected) {
            loadDetails("account");
            loadDetails("app");
        } else {
            dispatch(reset())
        }
    }, [connected, address, provider]);


    return (
        <Switch>
            <Route exact path={`/`}>
                <LandingView />
            </Route>
            <Route exact path={`/app`}>
                <AppView />
            </Route>
        </Switch>
    );
}

export default App;
